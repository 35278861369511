.pageLayout {
  --border-table-width: 2px;
  --border-td-width: 1px;
  --border-pattern: solid;
  --border-color: black;
  --background-color: var();

  --padding-width: 5px;
  --normal-font-size: 0.8rem;
  --detail-font-size: 0.65rem;

  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  .headerTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th {
      background-color: var(--color-skyblue);
      text-align: right;
      padding-right: var(--padding-width);
    }

    td {
      text-align: center;
    }

    tr:nth-child(1){
      td:nth-child(1){
        width: 15%;
      }

      td:nth-child(2){
        width: 55%;
      }

      td:nth-child(3){
        width: 15%;
      }

      td:nth-child(4){
        width: 15%;
      }
    }
  }

  .reportTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th {
      background-color: var(--color-skyblue);
      width: 19%;
    }

    th:first-child {
      width: 5%;
    }

    tr:nth-child(1) {
      height: 20%;
    }

    tr:nth-child(2) {
      height: 60%;
    }

    td {
      text-align: center;
    }

    tr:nth-child(3) {
      height: 30%;
      * {
        padding: 0;
        font-size: var(--detail-font-size);
      }
      td {
        border-bottom: var(--border-table-width) var(--border-pattern) var(--border-color);
      }
    }

    .circle {
      display: flex;
      width: 85%;
      height: 85%;
      border-radius: 50%;
      border: var(--border-table-width) var(--border-pattern) var(--color-violet);
      margin: 0 auto;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  .bodyTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    tr, th, td * {
      font-size: var(--normal-font-size);
    }

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th{
      background-color: var(--color-skyblue);
      padding-right: var(--padding-width);
      text-align: right;
    }
  }
}