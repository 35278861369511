div.back {
  background-color: #feffff;
  background: -moz-linear-gradient(top,  #feffff 0%, #d2ebf9 65%);
  background: -webkit-linear-gradient(top,  #feffff 0%,#d2ebf9 65%);
  background: linear-gradient(to bottom,  #feffff 0%,#d2ebf9 65%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#d2ebf9',GradientType=0 );
}
.test5 {
  border-bottom: 2px solid blue;
}

hr {
  width: 100%;
  height: 50%;
  border-top: 4px double #bbb;
  text-align: center;
}
hr:after {
  content: '\002665';
  display: inline-block;
  position: relative;
  top: -15px;
  padding: 0 10px;
  color: #bbb;
  font-size: 18px;
}
