.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;

  & > div {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }

    & > p {
      display: flex;
      align-items: inherit;
      justify-content: inherit;
      flex: 1;
      margin: 0;
      padding: 0 8px;
      height: 100%;
      border-radius: var(--radius-default);
      background-color: var(--color-label-background);
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__inline {
    display: inline-flex;
  }

  &.direction__vertical {
    flex-direction: column;

    & > div {
      margin-right: 0;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: var(--radius-default);
      background-color: rgba(0, 0, 0, .1);
      backdrop-filter: blur(6px);
      z-index: 10;
    }
  }

  .disabled__text {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    color: #00000073;
    font-size: 22px;
    text-shadow: 0 0 2px rgba(255, 255, 255, .3);
    z-index: 11;
  }
}
