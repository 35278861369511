.container {
  overflow: hidden;
  position: relative;

  .scroller {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    & > .footer {
      overflow: hidden;
    }

    & > .header {
      border-top-left-radius: var(--radius-default);
      border-top-right-radius: var(--radius-default);
      overflow: hidden;
    }

    & > .header > div {
      background-color: var(--color-primary);
      color: var(--color-secondary);
      font-size: 12px;
      font-weight: 700;
      user-select: none;
    }
  }
}

.body {
  & > div {
    overflow-x: hidden !important;
  }

  & > div > div {
    overflow: visible !important;
    display: flex;
    flex-direction: column;
    user-select: none;
    cursor: pointer;
  }

  & > div > div > div > div > div {
    background-color: var(--color-label-background);
  }

  & > div > div > div:nth-child(2n) > div > div {
    background-color: #f2f2f2;
  }

  & > div > div > div > div.group > div {
    background-color: #E3E3E3;
    font-weight: 600;
  }

  & > div > div > div > div:hover > div > div {
    background-color: var(--color-form-focus);
  }
}

.fixedColMain {
  overflow: hidden;
  position: absolute;
  top: 22px;
  left: 0;
  bottom: 34px;
  width: 500px;
  height: initial;
  background-color: #FFFFFF;
}

.fixedCol {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  background-color: #FFFFFF;
}

.bottom {
  margin: 0 !important;
  padding: 0 8px;
  border-bottom-left-radius: var(--radius-default);
  border-bottom-right-radius: var(--radius-default);
  background-color: #dcdcdc;
  font-size: 12px;

  .thumb {
    height: 15px;
    border-radius: var(--radius-default);
    background-color: #f0f0f0;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }

    &:active {
      background-color: #cccccc;
    }
  }

  .rail {
    flex: 1;
    height: 15px;
    border-radius: var(--radius-default);
    background-color: #d0d0d0;

    & > div {
      width: 100px;
      height: 100%;
      border-radius: var(--radius-default);
      background-color: #f0f0f0;
      cursor: pointer;
      user-select: all;

      &:hover {
        background-color: #f6f6f6;
      }

      &:active {
        background-color: #b4b4b4;
      }
    }
  }
}

.noevent {
  user-select: none;
  pointer-events: none;
}

.highlight {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px var(--color-tools-overlay);
    user-select: none;
    pointer-events: none;
    z-index: 2;
  }
}

.list {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-left: solid 2px #FFFFFF;
    background-color: var(--color-primary);
  }

  &::-webkit-scrollbar-thumb {
    border-left: solid 2px #FFFFFF;
    background-color: var(--color-secondary);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.footer {
  height: 32px;
  min-height: 32px;
  background-color: var(--color-primary);

  & > div {
    color: var(--color-secondary);
    font-weight: 600;
  }
}

