.item {
  cursor: pointer;
  color: var(--color-secondary);

  & > span {
    padding: 0 4px;
    font-size: 12px;
    user-select: none;
  }

  & > svg {
    font-size: 16px;
  }

  &.active {
    color: var(--color-blue);
  }

  &:hover {
    color: #6c84a8;
  }

  &:active {
    opacity: 0.7;
  }
}

.readonly {
  filter: grayscale(1);
  opacity: .5;
  pointer-events: none;
}
