.pageLayout {
  --color-peace: #ced6e0;
  --color-twinkleblue: #a4b0be;

  --border-table-width: 2px;
  --border-td-width: 1px;
  --border-pattern: solid;
  --border-color: var(--color-twinkleblue);
  --background-color: var(--color-peace);

  --padding-width: 5px;
  --title-font-size: 0.8rem;
  --normal-font-size: 0.8rem;
  --detail-font-size: 0.65rem;

  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  .titleBox {
    width: 100%;
    height: 100%;
    border: var(--border-td-width) var(--border-pattern) var(--border-color);
    font-size: var(--title-font-size);
    padding: var(--padding-width);
    text-align: center;
    font-weight: bolder;
    background-color: var(--background-color);
  }

  .bodyTable {
    width: 100%;
    height: 100%;
    border: var(--border-td-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    table {
      border-collapse: collapse;
    }

    tr:first-child{
      th:nth-child(1){
        width: 10%;
        padding-left: 4%;
        padding-right: 4%;
      }
    }

    tr:nth-child(23){
      th:nth-child(1){
        padding-left: 4%;
        padding-right: 4%;
      }
      th:nth-child(5){
        text-align: left;
      }
    }

    tr:nth-child(28){
      th:nth-child(1){
        padding-left: 4%;
        padding-right: 4%;
      }
    }

    tr:nth-child(14) {
      th:nth-child(1){
        width: 15%;
      }
      th:nth-child(2){
        width: 24%;
      }
      th:nth-child(3){
        width: 7%;
      }
      th:nth-child(4){
        width: 12%;
      }
      th:nth-child(5){
        width: 6%;
      }
      th:nth-child(6){
        width: 12%;
      }
      th:nth-child(7){
        width: 24%;
      }
    }

    tr, th, td * {
      font-size: var(--normal-font-size);
    }

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th{
      background-color: var(--background-color);
      padding-right: var(--padding-width);
      text-align: right;
    }
  }
}