.container {
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 100%;
  padding: 6px 0 0 8px;

  &:hover > .tab:after {
    opacity: 0.3;
  }
}

.tab {
  position: relative;
  height: 100%;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  cursor: pointer;
  user-select: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--radius-default) var(--radius-default) 0 0;
    background-color: #ffffff;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.17s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 25%;
    right: 0;
    width: 1px;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 0;
    transition: opacity 0.17s;
  }

  &:not(.active):hover:before {
    opacity: 0.5;
  }

  &.active:before {
    opacity: 1;
  }

  &__cover {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    & > svg {
      font-size: 18px;
      pointer-events: none;
    }

    & > p {
      margin: 0 45px 0 6px;
      font-size: 13px;
      font-weight: 700;
      pointer-events: none;
    }

    & > .star {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 12px;
      width: 36px;
      height: 100%;
      padding: 0 4px;
      color: #BABABA;
      z-index: 3;

      &:hover {
        opacity: 0.6;
      }

      &:active {
        opacity: 0.8;
      }

      & > svg {
        pointer-events: none;
        user-select: none;
      }
    }

    & > .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -12px;
      width: 36px;
      height: 100%;
      padding: 0 4px;
      font-size: 11px;
      z-index: 3;

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 0.8;
      }

      &:hover:before {
        content: '';
        position: absolute;
        --radius: 16px;
        width: var(--radius);
        height: var(--radius);
        border-radius: var(--radius);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
      }
    }
  }
}
