.overflowHidden {
  // overflow: hidden;
}

.cover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: -2px;
  bottom: 0;
  opacity: .3;
  user-select: none;
  pointer-events: none;
}

.disableDrag {
  & > *, & > *::after, & > *::before {
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    pointer-events: none;
    cursor: pointer;
  }
}

.headerContainer {
  display: flex;
  user-select: none;

  & > div {
    margin-right: 2px;
    color: var(--color-secondary);

    &:last-child {
      margin-right: 0;
    }
  }
}

.trailContainer {
  display: flex;
  user-select: none;

  & > div {
    height: 32px;
    min-height: 32px;
    background-color: var(--color-primary);

    & > div {
      color: var(--color-secondary);
      font-weight: 600;
    }
  }
}

.container {
  overflow: hidden;
  margin-right: 0 !important;
  border-radius: var(--radius-default);
}

.header {
  overflow: hidden;
  height: 32px;
  background-color: var(--color-primary);
  font-size: 12px;
  font-weight: 700;
}

.body {
  & > div {
    overflow-x: hidden !important;
  }

  & > div > div {
    overflow: visible !important;
    display: flex;
    flex-direction: column;
  }

  & > div > div > div:focus-within,
  & > div > div > div:focus {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: solid 1px var(--color-tools-overlay);
      user-select: none;
      pointer-events: none;
      z-index: 2;
    }
  }

  & > div > div > div > div {
    & > div[role="check-box"] {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.scrollContainer {
  overflow: hidden;
  display: block;
  position: relative;
  flex: 1;
  width: 100%;

  & > .scroller {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.fixedCol {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  background-color: #FFFFFF;
}

.fixedColMain {
  overflow: hidden;
  position: absolute;
  top: 34px;
  left: 0;
  bottom: 22px;
  width: 500px;
  height: initial;
  background-color: #FFFFFF;
}

.footer {
  padding: 0 8px;
  border-bottom-left-radius: var(--radius-default);
  background-color: #dcdcdc;
  font-size: 12px;

  .thumb {
    height: 15px;
    border-radius: var(--radius-default);
    background-color: #f0f0f0;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }

    &:active {
      background-color: #cccccc;
    }
  }

  .rail {
    flex: 1;
    height: 15px;
    border-radius: var(--radius-default);
    background-color: #d0d0d0;

    & > div {
      width: 100px;
      height: 100%;
      border-radius: var(--radius-default);
      background-color: #f0f0f0;
      cursor: pointer;
      user-select: all;

      &:hover {
        background-color: #f6f6f6;
      }

      &:active {
        background-color: #b4b4b4;
      }
    }
  }

  &.right {
    margin-right: 0;
    padding: 16px 0 10px 0;
    border-top-right-radius: var(--radius-default);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--radius-default);

    .thumb, .rail {
      width: 15px;
      min-width: 15px;
      max-width: 15px;
    }

    .rail {
      height: 100%;
    }

    .rail > div {
      width: 15px;
      min-width: 15px;
      max-width: 15px;
    }
  }
}

.noEvent {
  user-select: none;
  pointer-events: none;
}

.highlight {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px var(--color-tools-overlay);
    user-select: none;
    pointer-events: none;
    z-index: 2;
  }
}

.list {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-left: solid 2px #FFFFFF;
    background-color: var(--color-primary);
  }

  &::-webkit-scrollbar-thumb {
    border-left: solid 2px #FFFFFF;
    background-color: var(--color-secondary);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.menuPadding {
  overflow: hidden;
  height: 32px;
  background-color: var(--color-primary);
}

.pageContainer {
  & > div:not(.blank) {
    align-items: center !important;
    justify-content: center !important;
    width: 24px;
    max-width: 24px;
    height: 24px;
    background-color: #EAEAEA;
    color: #818181;
    cursor: pointer;

    &.active {
      color: #FF0000;
      background-color: #CDCDCD;
    }

    &:hover {
      color: #000000;
    }
  }

  & > div.blank {
    color: #BABABA;
  }
}
