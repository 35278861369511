/**
 * Global styles
 */

:root {
  --height-fixed-header: 97px;
  --radius-default: 4px;

  --color-blue: #3167b1;
  --color-brown: #b13131;
  --color-gray: #31b197;
  --color-green: #44b131;
  --color-pink: #ff3cbe;
  --color-red: #ff513c;
  --color-yellow: #d5ff3c;
  --color-orange: #ffab2d;
  --color-olive: #6ab04c;
  --color-skyblue: #ceddf3;
  --color-violet: #6c5ce7;
}

body {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/**
 * Themes
 */

.brown {
  --color-primary: #EBD69D;
  --color-primary-light: #FCEDC3;
  --color-primary-super-light: #F7F7F7;
  --color-primary-text: #EBBB3A;
  --color-secondary: #49433D;
  --color-tertiary: #564F49; // Side menu background when open - header
  --color-quaternary: #615951; // Side menu background when open - child
  --color-deep: #3F3935;
  --color-tools-text: #C9C7C5;
  --color-tools-overlay: #D2BD8A;
  --color-form-focus: #FFFADF;
  --color-label-background: #FAFAFA;
  --color-text-cover-background: #DDDDDD;
  --color-text-disabled-background: #EEEEEE;
  --color-text-active-background: #FFFFFF;
  height: 100%;

  &.contrast {
    --color-primary: #EDCA6C;
    --color-secondary: #36342D;
    --color-tertiary: #28261F; // Side menu background when open - header
    --color-quaternary: #403E35; // Side menu background when open - child
    --color-deep: #4A473D;
    --color-text-cover-background: #C1C1C1;
    --color-text-disabled-background: #E0E0E0;
    --color-text-active-background: #EBD69D;
  }
}

.blue {
  --color-primary: #CFD6DE;
  --color-primary-light: #B2D6E8;
  --color-primary-super-light: #F7F7F7;
  --color-primary-text: #007ACC;
  --color-secondary: #4D5C6C;
  --color-tertiary: #5F6D7B; // Side menu background when open - header
  --color-quaternary: #6C7C8B; // Side menu background when open - child
  --color-deep: #475565;
  --color-tools-text: #CACED3;
  --color-tools-overlay: #A5B2BF;
  --color-form-focus: #E8EBEE;
  --color-label-background: #FAFAFA;
  --color-text-cover-background: #DDDDDD;
  --color-text-disabled-background: #EEEEEE;
  --color-text-active-background: #FFFFFF;
  height: 100%;

  &.contrast {
    --color-primary: #C7D9EB;
    --color-secondary: #3C6A9B;
    --color-tertiary: #2F5681; // Side menu background when open - header
    --color-quaternary: #5384B9; // Side menu background when open - child
    --color-deep: #305B89;
    --color-text-cover-background: #C1C1C1;
    --color-text-disabled-background: #E0E0E0;
    --color-text-active-background: #CFD6DE;
  }
}

.pink {
  --color-primary: #DECFD2;
  --color-primary-light: #E8B2C1;
  --color-primary-super-light: #F7F7F7;
  --color-primary-text: #CC0058;
  --color-secondary: #6C4D5B;
  --color-tertiary: #7B5F6A; // Side menu background when open - header
  --color-quaternary: #8B6C7A; // Side menu background when open - child
  --color-deep: #654757;
  --color-tools-text: #D3CACF;
  --color-tools-overlay: #D99FBF;
  --color-form-focus: #EEE8EA;
  --color-label-background: #FAFAFA;
  --color-text-cover-background: #DDDDDD;
  --color-text-disabled-background: #EEEEEE;
  --color-text-active-background: #FFFFFF;
  height: 100%;

  &.contrast {
    --color-primary: #F0B8D1;
    --color-secondary: #D266B3;
    --color-tertiary: #C94EA6; // Side menu background when open - header
    --color-quaternary: #E780CA; // Side menu background when open - child
    --color-deep: #CF289F;
    --color-text-cover-background: #C1C1C1;
    --color-text-disabled-background: #E0E0E0;
    --color-text-active-background: #DECFD2;
  }
}


.gray {
  --color-primary: #E2E2E2;
  --color-primary-light: #F0F0F0;
  --color-primary-super-light: #F7F7F7;
  --color-primary-text: var(--color-blue);
  --color-secondary: #6F6F6F;
  --color-tertiary: #5E5E5E; // Side menu background when open - header
  --color-quaternary: #828282; // Side menu background when open - child
  --color-deep: #656565;
  --color-tools-text: #D4D4D4;
  --color-tools-overlay: #CECECE;
  --color-form-focus: #EEEEEE;
  --color-label-background: #FAFAFA;
  --color-text-cover-background: #DDDDDD;
  --color-text-disabled-background: #EEEEEE;
  --color-text-active-background: #FFFFFF;
  height: 100%;

  &.contrast {
    --color-primary: #EEEEEE;
    --color-secondary: #111111;
    --color-tertiary: #000000; // Side menu background when open - header
    --color-quaternary: #333333; // Side menu background when open - child
    --color-deep: #363636;
    --color-text-cover-background: #C1C1C1;
    --color-text-disabled-background: #E0E0E0;
    --color-text-active-background: #E2E2E2;
  }
}

/**
 * Popup
 */

.swal2-container {
  z-index: 100000 !important;
}

.swal2-actions {
  flex: 1;
  display: flex;

  & > button {
    flex: 1;
    height: 38px;
    padding: 8px 0;
    margin-left: 8px;
    border: solid 1px var(--color-blue) !important;
    border-radius: var(--radius-default);
    background: transparent !important;
    color: var(--color-blue) !important;
    font-size: 12px;
    font-weight: 400;
    outline: none !important;
    -webkit-appearance: none;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background: #3167b1 !important;
      color: #FFFFFF !important;
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
    }

    &:focus, &:active {
      outline: none !important;
    }

    &:active {
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.89);
    }

    & > svg {
      margin: 0 4px 0 -4px;
    }

    &.btn-success {
      border-color: var(--color-blue) !important;
      color: var(--color-blue) !important;

      &:hover {
        background: var(--color-blue) !important;
        color: #FFFFFF !important;
      }
    }

    &.btn-danger {
      border-color: var(--color-red) !important;
      color: var(--color-red) !important;

      &:hover {
        background: var(--color-red) !important;
        color: #FFFFFF !important;
      }
    }

    //&:first-child {
    //  border-top-left-radius: var(--radius-default);
    //  border-bottom-left-radius: var(--radius-default);
    //}
    //
    //&:last-child {
    //  border-top-right-radius: var(--radius-default);
    //  border-bottom-right-radius: var(--radius-default);
    //}
  }
}

.modal-buttons {
  font-size: 12px;

  .btn-success > button {
    border-color: var(--color-blue) !important;
    background-color: transparent;
    color: var(--color-blue) !important;

    &:hover {
      background: var(--color-blue) !important;
      color: #FFFFFF !important;
    }
  }

  .btn-danger > button {
    border-color: var(--color-red) !important;
    background-color: transparent;
    color: var(--color-red) !important;

    &:hover {
      background: var(--color-red) !important;
      color: #FFFFFF !important;
    }
  }
}

/**
 * Scrollbar
 */

.track-horizontal {
  position: absolute;
  height: 6px;
  transition: opacity 200ms ease 0s;
  opacity: 1;
  right: 2px;
  bottom: 0;
  left: 2px;
  border-radius: 2px;
  z-index: 1001;
}

.track-vertical {
  position: absolute;
  width: 6px;
  opacity: 0;
  right: 0;
  bottom: 2px;
  top: 2px;
  border-radius: 2px;
  transition: opacity 200ms, background-color 200ms, width 200ms;
  z-index: 1001;

  &:hover, &:active {
    width: 10px !important;
    border-left: solid 1px rgba(0, 0, 0, .05);
    background-color: rgba(0, 0, 0, .01);
  }
}

.thumb-horizontal {
  position: relative;
  display: block;
  height: 100%;
  cursor: pointer;
  border-radius: inherit;
  opacity: 0.67;
  width: 0px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    border-radius: inherit;
    background-color: var(--color-secondary);
  }

  &.reverse:before {
    background-color: var(--color-primary);
  }
}

.thumb-vertical {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: var(--radius-default);
  height: 601px;
  opacity: 0.72;
  transform: translateY(0px);
  transition: width 200ms ease 0s;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 80%;
    height: 100%;
    border-radius: inherit;
    background-color: var(--color-secondary);
    transition: width 200ms;
  }

  &.reverse:before {
    background-color: var(--color-primary);
  }
}

.scroll-container {
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

/*
 * Known issue regarding “select”: IE 9 ignores background color.
 */

input[type='color']:hover,
input[type='date']:hover,
input[type='datetime']:hover,
input[type='datetime-local']:hover,
input[type='email']:hover,
input[type='month']:hover,
input[type='number']:hover,
input[type='password']:hover,
input[type='search']:hover,
input[type='tel']:hover,
input[type='text']:hover,
input[type='time']:hover,
input[type='url']:hover,
input[type='week']:hover,
select:hover,
select[size]:hover,
textarea:hover {
  background-color: #FFFDEF !important;
}

input[type='color']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='email']:focus,
input[type='month']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='time']:focus,
input[type='url']:focus,
input[type='week']:focus,
select:focus,
select[size]:focus,
textarea:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='submit']:focus {
  background-color: #FFFDEF !important;
  outline: 0;
  z-index: 1;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;

  &:hover {
    background-color: #EFEFEF;
  }
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

@keyframes progressLinearMovement {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.tui-image-editor-header {
  display: none;
}

.tui-image-editor-container {
  margin-bottom: 10px !important;
  border-radius: var(--radius-default);
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-container, .tui-image-editor-container .color-picker-control .tui-colorpicker-palette-container ul, .tui-image-editor-container .color-picker-control .tui-colorpicker-palette-container {
  width: 100%;
  height: auto;
  white-space: normal;
  letter-spacing: -3px;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
  border: 0;
  border-radius: 100%;
  margin: 2px;
  background-size: cover;
  font-size: 1px;
  width: 16px;
  height: 16px;
}
