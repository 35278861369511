.container {
  display: flex;
  position: relative;
  height: 55px;
  padding: 0 24px;
  background-color: var(--color-secondary);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.preLoader {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  animation-duration: .3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  pointer-events: none;
  z-index: 2;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--color-primary-light);
    animation: growBar1 5s infinite, moveBar1 5s infinite;
    z-index: 2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--color-primary-light);
    animation: growBar2 5s infinite, moveBar2 5s infinite;
    z-index: 2;
  }

  @keyframes growBar1 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: scaleX(0.1);
      transform: scaleX(0.1);
    }
    36.6% {
      -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
      animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
      -webkit-transform: scaleX(0.1);
      transform: scaleX(0.1);
    }
    69.15% {
      -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
      animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
      -webkit-transform: scaleX(0.83);
      transform: scaleX(0.83);
    }
    100% {
      -webkit-transform: scaleX(0.1);
      transform: scaleX(0.1);
    }
  }

  @keyframes moveBar1 {
    0% {
      left: -105.16667%;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
    20% {
      left: -105.16667%;
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
      animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    }
    69.15% {
      left: 21.5%;
      -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
      animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    }
    100% {
      left: 95.44444%;
    }
  }

  @keyframes growBar2 {
    0% {
      -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
      animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
      -webkit-transform: scaleX(0.1);
      transform: scaleX(0.1);
    }
    19.15% {
      -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
      animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
      -webkit-transform: scaleX(0.57);
      transform: scaleX(0.57);
    }
    44.15% {
      -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
      animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
      -webkit-transform: scaleX(0.91);
      transform: scaleX(0.91);
    }
    100% {
      -webkit-transform: scaleX(0.1);
      transform: scaleX(0.1);
    }
  }

  @keyframes moveBar2 {
    0% {
      left: -54.88889%;
      -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
      animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    }
    25% {
      left: -17.25%;
      -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
      animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    }
    48.35% {
      left: 29.5%;
      -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
      animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    }
    100% {
      left: 117.38889%;
    }
  }
}

.user {
  display: flex;
  align-items: center;
  position: relative;

  & > .logo {
    padding-top: 3px;

    & > img {
      height: 42px;

      &:nth-child(2) {
        width: 42px;
        margin-left: 8px;
        border-radius: 100px;
      }
    }
  }

  & > p {
    display: flex;
    align-items: flex-end;
    padding-left: 16px;
    color: var(--color-tools-text);
    font-size: 15px;

    & > b {
      padding-right: 8px;
      font-size: 22px;
    }
  }
}

.tools {
  display: flex;
  justify-content: flex-end;
  flex: 1;

  & > button {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 55px 0 0;
    position: relative;
    height: 100%;
    padding: 0 0 4px 0;
    border: none;
    border-radius: 0;
    background: transparent;
    font-size: 11px;
    color: var(--color-tools-text);
    transition: color 0.3s;

    & > svg {
      font-size: 24px;
      z-index: 1;
    }

    & > p {
      padding: 0 0 0 2px;
      margin: 2px 0;
      z-index: 1;
    }

    & > .badge {
      position: fixed;
      padding: 1px 7px;
      background-color: var(--color-red);
      border-radius: 32px;
      font-size: 12px;
      color: #FFFFFF;
      transform: translate(-15px, -29px);
      z-index: 1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--color-tools-overlay);
      transform: translateY(100%);
      transition: transform 0.17s;
      z-index: 0;
    }

    &.bgRed:before {
      background-color: #C93939;
    }

    &:hover:not(:active) {
      color: var(--color-secondary);

      &.red {
        color: #96201e;
      }

      &.bgRed {
        color: var(--color-primary);
      }

      &:before {
        transform: translateY(0);
      }
    }

    &:active:before {
      transform: translateY(-100%);
    }
  }
}
