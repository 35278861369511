.calendar {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 !important;
  border: solid 1px var(--color-tools-overlay);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  z-index: 99999;
}

.defaultContainer {
  width: 243px;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: none !important;
  user-select: none;

  & > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 60px;
    height: 60px;
    margin: 1px 1px 0 0;
    background-color: #FFFFFF;
    transition: background-color .3s, color .7s;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;

    &:nth-child(-n+4) {
      margin-top: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:hover:not(.header):not(.footer) {
      background-color: var(--color-secondary);
      color: #FFFFFF;
    }

    &.active {
      border: solid 1px var(--color-secondary);
    }

    &.today {
      border: solid 1px var(--color-primary);
    }

    &.header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 60px !important;
      font-size: 14px;

      & > div > div {
        transition: background-color .3s, color .7s;

        &:hover {
          background-color: var(--color-secondary);
          color: #FFFFFF;
        }
      }
    }

    &.footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 33px !important;
      font-size: 12px;
    }
  }

  &.date {
    width: 237px;

    & > div {
      width: 33px;
      height: 33px;

      &:nth-child(-n+4) {
        margin-top: 1px;
      }

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(4n) {
        margin-right: 1px;
      }

      &:nth-child(7n + 1) {
        margin-right: 0;
      }

      &.other {
        color: #CCCCCC;
      }
    }
  }
}

.none {
  pointer-events: none;
  cursor: none;
}
