.container {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: var(--radius-default);

  & > div {
    position: absolute;
    display: block;
    width: 100%;

    & > div > div {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      background-color: var(--color-primary);
      color: var(--color-secondary);

      &:not(:first-child) {
        margin-left: 2px;
      }
    }
  }
}
