.container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  background-color: var(--color-label-background);
  border-radius: var(--radius-default);
  transition: background-color 0.17s;

  & > div {
    padding: 4px 8px 8px 8px;
  }

  & > .title {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 4px;
    padding-left: 8px;
    padding-bottom: 0;
    color: var(--color-secondary);
    font-size: 12px;
    transition: color 0.17s;

    .need {
      display: inline-block;
      padding-left: 1px;
      color: var(--color-red);
      transform: translateY(1px);
    }

    input {
      display: block;
      width: 100%;
      border: none;
      border-radius: var(--radius-default);
      background: transparent;
      color: var(--color-secondary);
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 12px;
      font-weight: 600;
    }

    & > .footer {
      position: absolute;
      right: 0;
      top: 0;
      margin: 2px 8px;
    }
  }

  .textField > div {
    overflow: hidden;
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--color-text-cover-background);
    border-radius: var(--radius-default);
    background-color: #ffffff;
    font-size: inherit;
    transition: border-color 0.17s;
  }

  .textField select {
    padding-left: 12px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 1px;
    right: 1px;
    height: 0;
    border-bottom-left-radius: var(--radius-default);
    border-bottom-right-radius: var(--radius-default);
    background-color: var(--color-primary-text);
    transition: height .1s;
  }

  &:focus-within {
    background-color: var(--color-form-focus);

    &::after {
      height: 2px;
    }

    & > .title input {
      color: var(--color-primary-text);
    }

    .textField > div {
      border-color: var(--color-text-active-background);
    }
  }
}
