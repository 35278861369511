.container {
  display: flex;
  position: relative;
  height: 42px;
  padding: 0 24px;
  background-color: var(--color-primary);
}

.menu {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 8px;
  color: var(--color-secondary);
  user-select: none;
  cursor: pointer;

  & > svg {
    font-size: 22px;
    opacity: 0.5;
    transition: transform 0.5s, opacity 0.5s;

    @media (max-width: 1400px) {
      font-size: 18px;
      margin-top: 1px;
    }
  }

  & > p {
    margin: 0 6px;
    font-size: 13px;
    font-weight: 800;
    opacity: 0.5;
    transition: transform 0.3s, opacity 0.5s;

    @media (max-width: 1400px) {
      font-size: 12px;
      font-weight: 700;
    }
  }

  &:before {
    --size: 12px;
    --size-negative: calc(var(--size) * -1);
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--size) / -2);
    left: calc(50% - (var(--size) / 2));
    width: 0;
    height: 0;
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid var(--color-secondary);
    transform: translateY(var(--size-negative));
    transition: transform 0.17s;
    z-index: 1;
  }

  &:hover,
  &.active {
    & > svg,
    & > p {
      opacity: 1;
      transform: translateY(3px);
    }

    &:before {
      transform: translateY(0);
    }
  }

  &:active {
    & > svg,
    & > p {
      opacity: 0.7;
    }
  }
}

.right {
  display: flex;
  flex: 1;
  position: relative;
}

.manager {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 12px;
  padding: 0 0 0 6px;
  height: 100%;
  transform: translateY(1px);

  &:before {
    content: '';
    position: absolute;
    border: solid 1px #DDDDDD;
    border-radius: 16px;
    width: 100%;
    height: 23px;
    background-color: rgba(0, 0, 0, .05);
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }

  & > button {
    color: #e80a0a !important;
    font-size: 11px !important;

    & > svg {
      fill: #3f3935;
      font-size: 14px;
    }

    &:before,
    &:after {
      display: none !important;
    }
  }

  &.active {
    user-select: none;
    pointer-events: none;

    &:before {
      border-color: rgba(255, 255, 255, .3);
      background-color: rgba(0, 0, 0, 0);
    }

    & > button {
      color: var(--color-tertiary) !important;

      & > svg {
        fill: var(--color-primary-text);
      }
    }
  }
}

.infomation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  flex: 1 1;

  & > .time {
    font-size: 15px;
    color: var(--color-secondary);

    & > span {
      padding-left: 2px;
      font-size: 12px;
      letter-spacing: -1px;
    }
  }
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  font-size: 13px;

  &__container {
    display: flex;
    position: relative;
    width: 275px;
    height: 76%;
    border-radius: 100px;
    background-color: #ffffff;

    & > div > div > div,
    & input {
      border-radius: 100px;
    }
  }

  &__option {
    display: flex;
    flex: 40% 0 0;
    align-items: center;
    justify-content: center;
    position: relative;

    select {
      padding: 0 16px;
      border: none;
      text-align-last: center;
    }
  }

  &__extra {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 25%;
      left: 0;
      width: 1px;
      height: 50%;
      background-color: #ededed;
      z-index: 2;
      pointer-events: none;
    }

    input,
    select {
      padding: 0 12px;
      border: 0;
    }

    & > div {
      justify-content: flex-start;
    }

    & input {
      border-radius: 100px;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 12px;
  }

  &__filter {
    overflow-y: auto;
    position: absolute;
    top: 38px;
    right: 15px;
    width: 254px;
    height: 220px;
    border-bottom-left-radius: var(--radius-default);
    border-bottom-right-radius: var(--radius-default);
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .05);
    user-select: none;
    z-index: 1;

    & > div > div {
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      &:active {
        opacity: .5;
      }

      &:nth-child(2n) {
        background: #F0F0F0;
      }
    }
  }
}
