.pageLayout {
  --border-table-width: 1px;
  --border-td-width: 1px;
  --border-pattern: solid;
  --border-color: var(--color-secondary);
  --background-color: var(--color-primary);

  --padding-width: 5px;
  --normal-font-size: 0.8rem;
  --detail-font-size: 0.65rem;

  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  .headerTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-radius: 4px;
    border-collapse: collapse;

    th, td {
      height: 24px;
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th {
      background-color: var(--background-color);
      padding-right: var(--padding-width);
      font-weight: bolder;
      text-align: right;
    }

    td {
      width: 90%;
      color: var(--color-violet);
      text-align: left;
    }
  }
}