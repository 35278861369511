.container {
  padding: 8px 16px;
  border-radius: var(--radius-default);
  background: url(../../../../assets/images/header-sub-bg.png) no-repeat center center;
  background-size: cover;

  & > div:not(:last-child):not(.buttons) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    margin-right: 14px;
    border: solid 1px var(--color-primary);
    border-radius: 36px;
    background-color: rgba(255, 255, 255, .38);

    & > span:nth-child(1) {
      color: var(--color-primary-text);
      font-weight: bold;
      user-select: none;
    }

    & > span:nth-child(2) {
      display: flex;
      justify-content: center;
      min-width: 70px;
      padding-left: 8px;
      color: var(--color-tertiary);
    }
  }

  & > div.buttons > div,
  & > div:last-child > div {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  & > div.buttons > div > button,
  & > div:last-child > div > button {
    overflow: hidden;
    position: relative;
    height: 26px;
    min-width: 64px;
    border: solid 1px var(--color-primary);
    background-color: #FFFFFF;
    color: var(--color-tertiary);

    & > svg:first-child {
      position: absolute;
      left: -16px;
      font-size: 46px;
      color: var(--color-primary);
      opacity: .38;
    }

    & > span {
      position: relative;
      z-index: 1;
    }

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .1);
    }
  }
}
