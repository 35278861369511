.container {
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  & > input {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    border: none;
    background-color: transparent;
    color: transparent !important;
    font-size: 12px;
    caret-color: var(--color-secondary);

    &:hover,
    &:focus {
      background-color: #FFFDEF !important;
    }
  }

  &:not(.transparent) > .readonly {
    background-color: var(--color-text-disabled-background);
  }

  & > .readonly {
    user-select: none;
    pointer-events: none;
  }

  &.transparent > input:hover,
  &.transparent > input:focus,
  &.transparent > textarea:hover,
  &.transparent > textarea:focus {
    background-color: transparent !important;
  }

  & > label {
    display: block;
    position: absolute;
    color: var(--color-secondary);
    pointer-events: none;
    user-select: none;
    z-index: 3;
  }

  &:focus-within > label {
    display: none;
  }

  & > .viewer {
    position: absolute;
    padding: 0 12px;
    pointer-events: none;
    user-select: none;
    z-index: 2;

    & > span {
      margin: 0 !important;
      letter-spacing: initial !important;
      font-weight: initial !important;
      font-size: 12px !important;
    }
  }
}
