.contents {
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - var(--height-fixed-header));
  background-color: var(--color-primary);

  & > :nth-child(1) {
    display: block;
    flex: 222px 0 0;
    position: relative;
    min-width: 222px;
    height: calc(100% - 6px);
    margin-top: 6px;
    border-top-right-radius: var(--radius-default);
    background-color: var(--color-secondary);
  }

  &__body {
    display: block;
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__view {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
    padding: 8px;
    background-color: #ffffff;

    & > div {
      display: contents;
    }
  }
}

.modals {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90000;

  .buttons {
    font-size: 12px;

    .btnSuccess > button {
      border-color: var(--color-blue) !important;
      background-color: transparent;
      color: var(--color-blue) !important;

      &:hover {
        background: var(--color-blue) !important;
        color: #FFFFFF !important;
      }
    }

    .btnDanger > button {
      border-color: var(--color-red) !important;
      background-color: transparent;
      color: var(--color-red) !important;

      &:hover {
        background: var(--color-red) !important;
        color: #FFFFFF !important;
      }
    }
  }
}
