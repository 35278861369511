.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../../../assets/images/login-bg.png) no-repeat 0 0;
  background-size: cover;

  @keyframes scale-animation {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(5);
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);
    transform: scale(0.1);
    animation: scale-animation;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    user-select: none;
    pointer-events: none;
  }
}

.login__box {
  display: flex;
  min-width: 500px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #4d5c6c;
    transform: skewX(-10deg);
    z-index: 0;
  }
}

.logo {
  --size: 74px;
  display: block;
  position: absolute;
  left: 395px;
  width: var(--size);
  height: var(--size);
  margin-top: -358px;

  &__container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 1;
    background: #FFFFFF;
    border-radius: 50%;
  }
}

.logo2 {
  --size: 450px;
  display: block;
  position: absolute;
  width: var(--size);
  height: var(--size);

  &__container2 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-filter: grayscale(100%);
    mix-blend-mode: multiply;
    opacity: .1;
  }
}

.title {
  position: relative;
  color: #ffffff;
  font-size: 82px;
  font-weight: 300;
  pointer-events: none;
  user-select: none;
  z-index: 2;
}

.remark {
  position: absolute;
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
  transform: translateX(245px) translateY(-110px);
  z-index: 2;
}

.form {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 64px;
  align-items: center;
  z-index: 2;

  &__input {
    display: flex;
    flex-direction: column;
    transform: translateX(-22px);

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;

      & > svg {
        margin-right: 16px;
        color: #4e4e4e;
        font-size: 32px;
      }

      & > div {
        overflow: hidden;
        width: 234px;
        height: 42px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.39);
      }
    }

    input {
      font-size: 14px;
    }

    input:hover {
      background-color: #cccccc !important;
    }

    input:focus {
      background-color: #ffffff !important;
    }
  }

  &__btn_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 42px;
    border: solid 1px #74899F;
    border-radius: var(--radius-default);
    margin-top: 32px;
    background: #74899F;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 300;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      border-color: #74899F;
      background: transparent;
      color: #74899F;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
