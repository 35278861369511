.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-default);
  background-color: var(--color-primary);

  & > span {
    color: var(--color-secondary);
    font-size: 12px;
    font-weight: 800;
  }

  &.highlight {
    background-color: var(--color-secondary);

    & > span {
      color: var(--color-primary);
    }
  }
}
