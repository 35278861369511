.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  z-index: 99999;
  transition: opacity 0.7s;
  opacity: 0;
  pointer-events: none;
  cursor: progress;

  &.preActive {
    pointer-events: all;
  }

  &.active {
    opacity: 1;
  }

  @keyframes scale-animation {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      transform: scale(5);
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.05);
    transform: scale(0.1);
    animation: scale-animation;
    animation-delay: 0s;
    animation-duration: 4s;
    animation-fill-mode: backwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    user-select: none;
    pointer-events: none;
  }
}

.remarks {
  color: #e2e2e2;
  font-size: 25px;
}

.info {
  display: block;
  position: absolute;
  bottom: 5vh;
  left: 5vw;
  color: #ffffff;
  font-size: 36px;
}
