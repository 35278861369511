.container {
  overflow-y: hidden;
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 100%;
  padding: 6px 0 0 8px;

  &:hover > .tab:after {
    opacity: 0.3;
  }
}

.tab {
  position: relative;
  height: 100%;
  padding: 0 12px;
  color: var(--color-secondary);
  cursor: pointer;
  user-select: none;
  transition: color .3s;

  &:before {
    content: '';
    position: absolute;
    right: 8px;
    bottom: 0;
    left: 8px;
    height: 3px;
    background-color: var(--color-primary);
    opacity: 0;
    z-index: 1;
    transform: translateY(100%);
    transition: transform .3s, opacity .3s;
  }

  &:not(:last-child):after {
    content: '';
    position: absolute;
    top: 25%;
    right: 0;
    width: 1px;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 0;
    transition: opacity 0.17s;
  }

  &:not(.active):hover:before {
    transform: translateY(0);
    opacity: 0.5;
  }

  &.active:before {
    transform: translateY(0);
    opacity: 1;
  }

  &.active {
    color: var(--color-primary-text);
  }

  &__cover {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    & > svg {
      font-size: 18px;
      pointer-events: none;
    }

    & > p {
      margin: 0 4px 0 6px;
      font-size: 13px;
      font-weight: 400;
      pointer-events: none;
    }

    & > .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -12px;
      width: 36px;
      height: 100%;
      padding: 0 4px;
      font-size: 11px;
      z-index: 3;

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 0.8;
      }

      &:hover:before {
        content: '';
        position: absolute;
        --radius: 16px;
        width: var(--radius);
        height: var(--radius);
        border-radius: var(--radius);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
      }
    }
  }
}
