.pageLayout {
  --border-table-width: 1px;
  --border-td-width: 1px;
  --border-pattern: solid;
  --border-color: black;

  --padding-width: 5px;
  --normal-font-size: 0.8rem;
  --detail-font-size: 0.65rem;

  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  .header {
    font-weight: bolder;
    font-size: var(--normal-font-size);

    * {
      margin-right: var(--padding-width);
    }
  }

  .headerTable1 {
    width: 100%;
    height: 100%;

    tr, th, td * {
      font-size: var(--normal-font-size);
    }

    th {
      width: 25%;
      text-align: left;
    }

    tr:nth-child(3), tr:nth-child(4), tr:nth-child(5), tr:nth-child(6){
      th{
        letter-spacing: 25px;
      }
    }


  }

  .headerTable2 {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    tr, th, td * {
      font-size: var(--normal-font-size);
    }

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th {
      width: 15%;
      //background-color: var(--color-skyblue);
      padding-right: var(--padding-width);
      text-align: center;
    }

    tr:nth-child(1) {
      th:nth-child(1), th:nth-child(3) {
        width: 10%;
        padding-left: 4%;
        padding-right: 4%;
      }
    }

    tr:nth-child(2) {
      th:nth-child(1), th:nth-child(4) {
        width: 10%;
        padding-left: 4%;
        padding-right: 4%;
      }

      td:nth-child(2) {
        width: 10%;
      }

      td:nth-child(3) {
        width: 30%;
      }

      td:nth-child(5), td:nth-child(6) {
        width: 15%;
      }
    }
  }

  .title{
    width: 100%;
    height: 100%;
    border-bottom: var(--border-table-width) var(--border-pattern) var(--border-color);
    font-size: var(--normal-font-size);
    font-weight: bolder;

    span {
      letter-spacing: 25px;
      padding: var(--padding-width);
    }
  }

  .contents{
    width: 100%;
    height: 100%;
  }
}
