.container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: -2px;
  bottom: 0;
  opacity: .3;
  user-select: none;
  pointer-events: none;
}
