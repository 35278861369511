.container {
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  & > input {
    width: 100%;
    height: 100%;
    padding: 0 12px;
    border: none;
    background-color: transparent;
    font-size: 12px;
  }

  & > textarea {
    width: 100%;
    height: 100%;
    padding: 12px 8px;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-family: 돋움체, serif !important;
    line-height: 18px;
    resize: none;
  }

  &.noPadding > input,
  &.noPadding > textarea {
    padding: 0 0;
  }

  &:not(.transparent).readonly {
    user-select: none;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-text-disabled-background);
      opacity: .5;
    }
  }

  &.transparent > input:hover,
  &.transparent > input:focus,
  &.transparent > textarea:hover,
  &.transparent > textarea:focus {
    background-color: transparent !important;
  }

  & > label {
    display: block;
    position: absolute;
    color: var(--color-secondary);
    pointer-events: none;
    user-select: none;
    line-height: 0;
  }

  &:focus-within > label {
    display: none;
  }
}
