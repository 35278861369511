.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
  cursor: pointer;

  svg g > path {
    stroke: var(--color-secondary);
  }

  &.active {
    svg g > path {
      stroke: var(--color-blue);
    }
  }

  &:hover {
    svg g > path {
      stroke: #6c84a8;
    }
  }

  &:active {
    opacity: 0.7;
  }

  &.readonly {
    filter: grayscale(1);
    opacity: .5;
    pointer-events: none;
  }

  &.noMargin {
    margin: 0;
  }
}
