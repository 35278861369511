.container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  padding: 16px 0 16px 16px;
  color: var(--color-tools-text);
  user-select: none;

  & > svg {
    font-size: 34px;
  }

  & > p {
    margin: 0 8px;
    font-size: 16px;
  }
}

.child {
  display: block;
  width: 100%;
}

.buttons {
  height: 48px;
  background-color: var(--color-tools-overlay);

  & > div > button {
    margin: 0 !important;
    border: 0 !important;
  }
}

.menu {
  overflow-x: hidden;
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  padding: 0 0 0 16px;
  color: #FFFFFF;
  user-select: none;
  cursor: pointer;

  & > p {
    position: relative;
    margin: 0 8px;
    font-size: 14px;
    z-index: 2;
  }

  & > svg {
    position: relative;
    font-size: 16px;
    z-index: 2;
    transition: transform 0.3s;

    &.active {
      transform: rotateZ(90deg);
    }

    &.hasAnyChild {
      font-size: 11px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-deep);
    z-index: 1;
    transform: translateX(-100%);
    transition: transform 0.17s;
  }

  &:hover,
  &.active {
    &:before {
      transform: translateX(0);
    }
  }

  &:active:before {
    opacity: 0.7;
  }

  .colorOpen {
    color: #F7CC46;
  }

  .colorClose {
    color: #FFD576;
  }

  &.open {
    &.first {
      background-color: var(--color-tertiary);
    }

    &.first::after {
      content: '';
      display: block;
      position: absolute;
      width: 3px;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: var(--color-primary);
      z-index: 2;
    }

    &.secondary {
      background-color: var(--color-quaternary);
    }

    &.tertiary {
      background-color: var(--color-quaternary);
    }
  }

  &.first {
    height: 55px;
    border-top: solid 1px var(--color-deep);
  }

  &.secondary {
    padding: 0 0 0 calc(16px + 1px * 1);

    & > p {
      font-size: 13px;
    }
  }

  &.tertiary {
    padding: 0 0 0 calc(16px + 8px * 2);

    & > p {
      font-size: 13px;
    }
  }

  &.quaternary {
    padding: 0 0 0 calc(16px + 8px * 4);

    & > p {
      font-size: 13px;
    }
  }
}
