.container {
  overflow: hidden;
  display: flex;
  position: absolute;
  left: calc(50% + 14px);
  height: 19px;
  border-radius: 32px;
  color: #FFFFFF;
  font-size: 11px;
  transform: translateY(-6px);
  opacity: 0.85;

  & > span {
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 19px;
    background-color: var(--color-secondary);
  }
}
