.container {
  overflow: hidden;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: initial;
  font-size: initial;
  z-index: 88888;

  & > .background {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.67);
    z-index: 1;
  }

  & > .contents {
    overflow: hidden;
    position: relative;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 128px);
    border-radius: var(--radius-default);
    background-color: #ffffff;
    z-index: 2;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.05);
  }

  &.visible {
    visibility: visible;
  }
}
