.combobox {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  z-index: 99999;

  & > .selector {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    & > .search {
      width: 100%;
      height: 100%;
      font-size: inherit;

      & > input {
        width: 100%;
        height: 100%;
        border: none;
        background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+) no-repeat right 8px center;
        background-size: 8px;
      }
    }

    & > .dropdown {
      overflow-y: auto;
      position: absolute;
      width: 100%;
      max-height: 150px;
      background-color: #FFFFFF;
      border-bottom-left-radius: var(--radius-default);
      border-bottom-right-radius: var(--radius-default);
      box-shadow: 0px 12px 8px 0px rgba(0, 0, 0, .15);
      transform: translateY(2px);
      z-index: 20;

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        border-left: solid 2px #FFFFFF;
        background-color: var(--color-primary);
      }

      &::-webkit-scrollbar-thumb {
        border-left: solid 2px #FFFFFF;
        background-color: var(--color-secondary);
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 30px;
        font-size: 12px;
        user-select: none;
        cursor: pointer;

        &:nth-child(2n - 1) {
          background-color: rgba(0, 0, 0, .02);
        }

        &:hover {
          background-color: #FFFDEF;
        }
      }
    }
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: inherit;

  & > .view {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding-right: 16px;
    border: none;
    /* Change the px value here to change the distance */
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjMzbDIuODI5LTIuODMgOS4xNzUgOS4zMzkgOS4xNjctOS4zMzkgMi44MjkgMi44My0xMS45OTYgMTIuMTd6Ii8+PC9zdmc+) no-repeat right 8px center;
    background-size: 8px;
    color: var(--color-secondary);
    font-size: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;

    &:hover {
      background-color: #FFFDEF !important;
    }
  }

  &.readonly {
    filter: grayscale(1);
    opacity: .5;
    pointer-events: none;
    cursor: pointer;
  }
}
