.footer {
  display: inline-flex;
  justify-content: center;
  position: absolute;
  right: 0;
  padding: 4px 8px;
  height: 100%;

  & > div:not(:last-child) {
    margin-right: 8px;
  }
}

.screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
