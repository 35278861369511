table.table {
  border: 1px;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

table.table td, table.table th {
  border: 1px solid #ff513c;
  padding: 1.5px 2px;
  height: 2px;
}
table.table th {
  font-weight: normal;
  font-size: 12px;
  background: #EEEEEE;
}

.thFontColor {
  color: #ff513c;
}

table.table thead th {
  color: #ff513c;
}

table.blueTable {
  border: 1px;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

table.blueTable td, table.blueTable th {
  border: 1px solid #EAEAEA;
  padding: 3px 2px;
}
table.blueTable th {
  font-weight: normal;
  font-size: 12px;
  color: #475565;
  background: #b2d6e8;
}

.tdColor {
  background: #EEEEEE;
}

.slimWidth {
  width: 2.5%
}

.squareWidth {
  width: 5%
}

.square2Width {
  width: 7%
}

.middleWidth {
  width: 11%
}

.mlargeWidth {
  width: 17%
}

.largeWidth {
  width: 20%
}

.largesWidth {
  width: 24%
}

.need {
  display: inline-block;
  color: var(--color-red);
  margin-left: -9px;
  transform: translateY(3px);
}




