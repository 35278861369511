.container {
  height: 100%;
  border-radius: var(--radius-default);

  &.mini {
    height: initial;

    & > button {
      line-height: 100%;
      padding: 4px 6px;
    }
  }

  &.full {
    flex: 1;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--color-blue);
    border-radius: var(--radius-default);
    width: 100%;
    height: 100%;
    padding: 0 6px;
    background: transparent;
    color: var(--color-blue);
    font-size: inherit;
    transition: box-shadow .3s, background .17s ease-in-out, border .17s, color cubic-bezier(0.93, -0.04, 0.75, 0.9) .1s;

    &:hover {
      background: var(--color-blue);
      color: #FFFFFF;
    }

    &:active {
      opacity: .7;
    }

    & > svg {
      margin: 0 4px 0 -4px;
    }

    & > .badge {
      position: fixed;
      padding: 1px 7px;
      background-color: var(--color-red);
      border-radius: 32px;
      font-size: 12px;
      color: #FFFFFF;
      transform: translate(-18px, -16px);
      z-index: 1;
    }
  }

  &.red > button {
    border-color: var(--color-red);
    color: var(--color-red);

    &:hover {
      background: var(--color-red);
      color: #FFFFFF;
    }
  }

  &.onlyIcon {
    & > button {
      border: none;
      background: transparent;
      box-shadow: none;
      padding: 0 2px;

      & > svg {
        position: relative;
        margin: 0;
        z-index: 10;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 42px;
        height: 42px;
        border-radius: 42px;
        background-color: var(--color-secondary);
        z-index: 5;
        opacity: 0;
        transform: scale(0);
        transition: opacity .3s, transform .7s;
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 38px;
        height: 38px;
        border-radius: 38px;
        border: solid 1px var(--color-primary);
        z-index: 6;
        opacity: 0;
        transform: scale(0);
        transition: opacity .3s, transform cubic-bezier(0.64, 0.32, 0.22, 1.55) .7s;
      }

      &:hover {
        color: var(--color-primary);

        &:before {
          transform: scale(1);
          opacity: .8;
        }

        &:after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  &.disabled {
    opacity: .5;
    user-select: none;
    pointer-events: none;
  }

  &.material > button {
    position: relative;
    margin: 0 8px;
    border: none;
    border-left: solid 2px #FFFFFF;
    border-radius: 0 !important;
    color: var(--color-secondary);
    font-size: 0;
    transform: translateY(0);
    transition: background-color .7s, color cubic-bezier(0.93, -0.04, 0.75, 0.9) .3s;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 5px;
      background-color: var(--color-secondary);
      z-index: 1;
      transition: bottom 0.3s, height cubic-bezier(0, 0, 1, -0.18) 0.3s, opacity 0.3s;
      opacity: .3;
    }

    &:hover {
      color: #FFFFFF;
      background-color: var(--color-secondary);

      &:before {
        bottom: 5px;
        height: calc(100% - 5px);
        background-color: var(--color-primary);
        color: var(--color-primary);
        opacity: .2;
      }
    }
  }
}
