.container {
  position: relative;
  padding: 0 8px 0 58px;
  border-radius: var(--radius-default);
  background-color: var(--color-primary);

  &.sub:before {
    content: '';
    position: absolute;
    top: 0;
    left: 40px;
    right: 0;
    height: 1px;
    background-color: rgba(255, 255, 255, .4);
    z-index: 1;
  }

  &.sub:after {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: var(--color-primary);
    z-index: 0;
  }

  & > div {
    margin: 0;

    span:nth-child(1) {
      flex: auto 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px 0 16px;
      color: var(--color-secondary);
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 1px;
    }

    & > div {
      flex: 1;
      display: flex;
      align-items: center !important;
      justify-content: center !important;

      &:before {
        top: 19% !important;
        left: 1px !important;
        right: 1px !important;
        bottom: 19% !important;
      }

      & > div:nth-child(1),
      & > input,
      & > select {
        flex: 1;
        height: 67%;
        border: solid 1px var(--color-tools-overlay);
        background-color: #FFFFFF;
        color: var(--color-secondary);
        font-size: 12px;

        &:focus, &:hover {
          background-color: #FFFFFF;
        }
      }
    }

    & > button {
      position: relative;
      margin: 0 8px;
      border: none;
      border-left: solid 2px #FFFFFF;
      border-radius: 0;
      color: var(--color-secondary);
      font-size: 0;
      transform: translateY(0);
      transition: background-color .7s, color cubic-bezier(0.93, -0.04, 0.75, 0.9) .3s;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 5px;
        background-color: var(--color-secondary);
        z-index: 1;
        transition: bottom 0.3s, height cubic-bezier(0, 0, 1, -0.18) 0.3s, opacity 0.3s;
        opacity: .3;
      }

      &:hover {
        background-color: var(--color-secondary);

        &:before {
          bottom: 5px;
          height: calc(100% - 5px);
          background-color: var(--color-primary);
          color: var(--color-primary);
          opacity: .2;
        }
      }
    }

    &:last-child {
      border-top-right-radius: var(--radius-default);
      border-bottom-right-radius: var(--radius-default);

      & > button {
        border-top-right-radius: var(--radius-default);
        border-bottom-right-radius: var(--radius-default);
      }
    }
  }
}

.icon {
  position: absolute;
  color: #FFFFFF;
  transform: translateX(-58px);

  & > svg {
    background-size: cover;
    font-size: 58px;
    transform: translateX(-4px) translateY(-4px);
  }
}
