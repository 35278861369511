.container {
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: var(--radius-default);

  &.full {
    flex: 1;
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--color-blue);
    border-radius: var(--radius-default);
    width: 100%;
    height: 100%;
    padding: 0 6px;
    background: transparent;
    color: var(--color-blue);
    font-size: inherit;
    transition: box-shadow .3s, background .17s ease-in-out, border .17s, color cubic-bezier(0.93, -0.04, 0.75, 0.9) .1s;

    &:hover {
      background: var(--color-blue);
      color: #FFFFFF;
    }

    &:active {
      opacity: .7;
    }

    & > svg {
      margin: 0 4px 0 -4px;
    }

    & > .button__body {
      transition: transform .3s ease-out;

      &.loading {
        transform: translateY(-300%);
      }
    }
  }

  &.red > button {
    border-color: var(--color-red);
    color: var(--color-red);

    &:hover {
      background: var(--color-red);
      color: #FFFFFF;
    }
  }

  &.onlyIcon {
    & > button {
      border: none;
      background: transparent;
      box-shadow: none;
      padding: 0 2px;

      & > svg {
        margin: 0;
      }

      &:hover {
        color: var(--color-secondary);
      }
    }
  }

  .loader__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-blue);
    transition: transform .05s ease-out;
  }

  &.red > .loader__background {
    background: var(--color-red);
  }

  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: 14px;
    z-index: 2;
  }
}
