@charset "utf-8";

/* 
 * 1. Firefox doesn’t inherit font family specified for “select”. More 
 *    information: https://github.com/nathansmith/formalize/issues/41
 * 2. Change this according to your site’s font (family) configuration. Be 
 *    aware that changing font size and line height affects vertical alignment
 *    of check boxes and radio buttons. There’s no “holy grail” for vertically
 *    aligning those input types.
 * 3. If you use a universal reset for “margin” and “padding” in your site’s 
 *    base CSS file you don’t need this.
 * 4. Corrects color not being inherited in IE8/9.
 * 5. Tailored to the demo page with all given HTML markup dependencies and 
 *    so on. Please change according to your needs.
 */

form,
input,
select,
optgroup, /* 1 */
textarea,
button {
  margin: 0; /* 3 */
  padding: 0; /* 3 */
}

fieldset {
  border: 1px solid #ccc;
  margin: 1.5em 0; /* 5 */
  padding: 1em;
}

legend {
  border: 0; /* 4 */
  color: #888;
  padding: 0 0.5em;
}

/* 5 */

label {
  display: block;
}

/* 5 */

input + label {
  margin-top: 1em;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  display: inline-block;
  margin: 0 2em 0 0;
}

/* 
 * 1. WebKit browsers use “pointer” only when “size” attribute with value 0
 *    or 1 is present.
 */

label,
input[type='checkbox'],
input[type='radio'],
select, /* 1 */
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

/*
 * Improves appearancy and consistency in all browsers.
 * 
 * 1. https://github.com/nathansmith/formalize/issues/43
 * 2. Firefox sets line height using “!important” in the UA style sheet.
 */

input,
select, /* 1 */
textarea,
button {
  line-height: normal; /* 2 */
  vertical-align: top;
}

/*
 * The reason why I list all input types one by one and not just “input” is 
 * this: I don’t want to address input types “file” and “range”.
 */

button {
  background-color: transparent;
  border: none;
}

/*
 * 1. A font size of 100% (as specified in the first rule set) would render
 *    check boxes and radio buttons bigger in Webkit browsers (Mac OS X).
 * 2. Needed for browser specific positioning later on.
 * 3. Improves consistency in all browers.
 */

input[type='checkbox'],
input[type='radio'] {
  font-size: 0.6875em; /* 1 */
  position: relative; /* 2 */
  vertical-align: middle; /* 3 */
}

/* 
 * Opera (Mac OS X) needs this. More information: 
 * https://github.com/nathansmith/formalize/issues/45
 */

input[type='checkbox'] {
  margin: 2px 1px;
}

input[type='radio'] {
  margin: 1px;
}

/* https://github.com/nathansmith/formalize/issues/42 */

/* 
 * Only Firefox understands this. Result: Opera and IE have italic font style,
 * WebKit and Firefox normal font style.
 */

optgroup {
  font-style: normal;
  font-weight: 700;
}

/*
 * 1. Removes default vertical scrollbar in IE browsers.
 */

textarea {
  min-height: 5em;
  overflow: auto; /* 1 */
  resize: vertical;
  width: 100%;
}

/*
 * All clickable buttons.
 *
 * 1. Fallback for browsers not supporting gradients (like IE 9).
 * 2. “Pushed in” effect for active state.
 */

input[type='reset'],
input[type='submit'] {
  background: #e5e5e5 url('img/button.png') repeat-x; /* 1 */
  background-image: -moz-linear-gradient(#fff, #e5e5e5);
  background-image: -ms-linear-gradient(#fff, #e5e5e5);
  background-image: -o-linear-gradient(#fff, #e5e5e5);
  background-image: -webkit-linear-gradient(#fff, #e5e5e5);
  background-image: linear-gradient(#fff, #e5e5e5);
  border-color: #ddd #bbb #999;
  //font-weight: 700;
  line-height: normal;
  overflow: visible;
  padding: 3px 0.5em;
  //text-shadow: #fff 0 1px 1px;
}

input[type='reset']:hover,
input[type='submit']:hover {
  background-image: -moz-linear-gradient(#fff, #d9d9d9);
  background-image: -ms-linear-gradient(#fff, #d9d9d9);
  background-image: -o-linear-gradient(#fff, #d9d9d9);
  background-image: -webkit-linear-gradient(#fff, #d9d9d9);
  background-image: linear-gradient(#fff, #d9d9d9);
}

/* 
 * Disabled states.
 */

input[type='color'][disabled],
input[type='date'][disabled],
input[type='datetime'][disabled],
input[type='datetime-local'][disabled],
input[type='email'][disabled],
input[type='month'][disabled],
input[type='number'][disabled],
input[type='password'][disabled],
input[type='search'][disabled],
input[type='tel'][disabled],
input[type='text'][disabled],
input[type='time'][disabled],
input[type='url'][disabled],
input[type='week'][disabled],
select[disabled],
textarea[disabled] {
  background-color: #eee !important;
}

input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled],
button[disabled] {
  color: #888;
  cursor: default;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/*
 * WebKit specific stuff.
 */

@media (-webkit-min-device-pixel-ratio: 0) {
  input[type='checkbox'] {
    top: -1px;
  }

  input[type='radio'] {
    top: -2px;
  }

  input[type='search'] {
    -webkit-appearance: none;
  }

  input[type='search']::-webkit-search-decoration {
    display: none;
  }

  select {
    -webkit-appearance: none;
    border-radius: 0;
  }

  /*
	 * 1. https://github.com/nathansmith/formalize/issues/44
	 */

  select[multiple],
	select[multiple][size], /* 1 */
	select[size] {
    background-image: none;
    padding-right: 3px;
  }

  select,
  select[size='0'],
  select[size='1'] {
    background: transparent url('img/select_arrow.gif') no-repeat right 4px center;
    padding-right: 1em;
  }

  ::-webkit-input-placeholder {
    color: #888;
  }
}

/*
 * Firefox specific stuff.
 */

@-moz-document url-prefix() {
  input[type='checkbox'] {
    top: -2px;
  }

  input[type='radio'] {
    top: -1px;
  }
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #888;
}

/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */

button::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
  border: 0;
  padding: 0;
}
