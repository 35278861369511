.container {
  position: relative;
  display: block;
  user-select: none;

  & > div {
    position: absolute;
    width: 110px;
    height: 70px;
    text-align: center;

    & > div:nth-child(1) {
      padding: 8px;
      background-color: var(--color-blue);
      border-radius: var(--radius-default);
      color: #FFFFFF;

      & > span {
        display: block;
        color: var(--color-primary);
      }
    }

    &.yet > div:nth-child(1) {
      background-color: var(--color-primary);
      color: var(--color-secondary);

      & > span {
        color: var(--color-red);
      }
    }

    &.alert:before {content: '';
      position: absolute;
      top: -11px;
      right: -17px;
      width: 32px;
      height: 32px;
      background: url(../../../../containers/img/alert.gif) no-repeat;
      background-size: contain;
    }

    & > div:nth-child(2) {
      padding: 6px;
    }

    &:nth-child(1) {
      transform: translateX(8px) translateY(100px);
    }

    &:nth-child(2) {
      transform: translateX(8px) translateY(200px);

      & > div:nth-child(2) {
        position: absolute;
        transform: translateX(42px) translateY(-58px);
        color: var(--color-green);
      }
    }

    &:nth-child(3) {
      transform: translateX(170px) translateY(180px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-30px) translateY(-91px);
        color: var(--color-green);
      }
    }

    &:nth-child(4) {
      transform: translateX(170px) translateY(8px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-32px) translateY(-6px);
        color: var(--color-green);
      }
    }

    &:nth-child(5) {
      transform: translateX(330px) translateY(8px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(130px) translateY(-50px);
        color: var(--color-green);
      }
    }

    &:nth-child(6) {
      transform: translateX(330px) translateY(100px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-108px) translateY(-51px);
        color: var(--color-green);
      }
    }

    &:nth-child(7) {
      transform: translateX(490px) translateY(8px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-29px) translateY(-3px);
        color: var(--color-green);
      }
    }

    &:nth-child(8) {
      transform: translateX(520px) translateY(100px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-43px) translateY(-52px);
        color: var(--color-green);
      }
    }

    &:nth-child(9) {
      transform: translateX(600px) translateY(180px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(79px) translateY(-93px);
        color: var(--color-green);
      }
    }

    &:nth-child(10) {
      transform: translateX(680px) translateY(100px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-30px) translateY(-52px);
        color: var(--color-green);
      }
    }

    &:nth-child(11) {
      transform: translateX(800px) translateY(180px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-23px) translateY(-71px);
        color: var(--color-green);
      }
    }

    &:nth-child(12) {
      transform: translateX(650px) translateY(8px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(50px) translateY(2px);
        color: var(--color-green);
      }
    }

    &:nth-child(13) {
      transform: translateX(800px) translateY(8px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-14px) translateY(-5px);
        color: var(--color-green);
      }
    }

    &:nth-child(14) {
      transform: translateX(860px) translateY(100px);

      & > div:nth-child(3) {
        position: absolute;
        transform: translateX(-38px) translateY(-52px);
        color: var(--color-green);
      }
    }
  }
}
