.container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  background-color: lightslategray;
}

.header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //flex: 1;
  width: 20%;
  //background-color: #4d5c6c;
  font-weight: bold;
  color: #d5ff3c;
}

.contents {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  //flex: 4;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  //font-weight: bold;
  width: 70%;
  height: 60%;
  text-align: right;
  //background-color: yellow;
}

.contents_label {
  display: inline-block;
  text-align: right;
  width: 70%;
}

.contents_data {
  display: inline-block;
  text-align: right;
  width: 30%;
}
