/* latin-ext */
@font-face {
    font-family: 'Oxygen Mono';
    font-style: normal;
    font-weight: 400;
    src: local('Oxygen Mono'), local('OxygenMono-Regular'), url(./h0GsssGg9FxgDgCjLeAd7hjWx-6tPUUvkdU.woff2) format('woff2');
  unicode-range: U+30-39;
  }
  /* latin */
  @font-face {
    font-family: 'Oxygen Mono';
    font-style: normal;
    font-weight: 400;
    src: local('Oxygen Mono'), local('OxygenMono-Regular'), url(./h0GsssGg9FxgDgCjLeAd7hjYx-6tPUUv.woff2) format('woff2');
    unicode-range: U+30-39;
  }