.overflowHidden {
  //overflow: hidden;
}

.disableDrag {
  & > *, & > *::after, & > *::before {
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-app-region: no-drag;
    pointer-events: none;
    cursor: pointer;
  }
}

.headerContainer {
  display: flex;
  user-select: none;

  & > div {
    margin-right: 2px;
    color: var(--color-secondary);

    &:last-child {
      margin-right: 0;
    }
  }
}

.container {
  overflow: hidden;
  margin-right: 0 !important;
  border-radius: var(--radius-default);
}

.header {
  overflow: hidden;
  height: 32px;
  background-color: var(--color-primary);
  font-size: 12px;
}

.body {
  & > div {
    overflow-x: hidden !important;
  }

  & > div > div {
    overflow: visible !important;
    display: flex;
    flex-direction: column;
  }

  & > div > div > div > div {
    background-color: var(--color-label-background);

    & > div[role="check-box"] {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  & > div > div:nth-child(2n) > div > div {
    background-color: #f2f2f2;
  }
}

.scrollContainer {
  overflow: hidden;
  display: block;
  position: relative;
  flex: 1;
  width: 100%;

  & > .scroller {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      border-left: solid 2px #FFFFFF;
      background-color: var(--color-primary);
    }

    &::-webkit-scrollbar-thumb {
      border-left: solid 2px #FFFFFF;
      background-color: var(--color-secondary);
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

.footer {
  padding: 0 8px;
  border-bottom-left-radius: var(--radius-default);
  background-color: #dcdcdc;
  font-size: 12px;

  .thumb {
    height: 15px;
    border-radius: var(--radius-default);
    background-color: #f0f0f0;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }

    &:active {
      background-color: #cccccc;
    }
  }

  .rail {
    flex: 1;
    height: 15px;
    border-radius: var(--radius-default);
    background-color: #d0d0d0;

    & > div {
      width: 100px;
      height: 100%;
      border-radius: var(--radius-default);
      background-color: #f0f0f0;
      cursor: pointer;
      user-select: all;

      &:hover {
        background-color: #f6f6f6;
      }

      &:active {
        background-color: #b4b4b4;
      }
    }
  }

  &.right {
    margin-right: 0;
    padding: 16px 0 10px 0;
    border-top-right-radius: var(--radius-default);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: var(--radius-default);

    .thumb, .rail {
      width: 15px;
      min-width: 15px;
      max-width: 15px;
    }

    .rail {
      height: 100%;
    }

    .rail > div {
      width: 15px;
      min-width: 15px;
      max-width: 15px;
    }
  }
}

.noevent {
  user-select: none;
  pointer-events: none;
}

.highlight {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px var(--color-tools-overlay);
    user-select: none;
    pointer-events: none;
    z-index: 2;
  }
}
