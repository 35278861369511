.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  & > .contents {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 2px;
    border: solid 1px #EEEEEE;
    border-radius: var(--radius-default);

    & > .image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    & > .edit {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .nothing {
    border-radius: var(--radius-default);
    background-color: var(--color-label-background);

    & > span {
      color: #515151;
      font-size: 13px;
    }
  }

  & > .footer {
    font-size: 14px;
  }
}
