.container {

}

.header {
  user-select: none;
  border-bottom: solid 1px var(--color-form-focus);

  & > div {
    padding: 4px;
  }
}

.dayRow {
  border-bottom: solid 1px var(--color-primary-super-light);

  &:last-child {
    border-bottom: 0;
  }
}

.dayContainer {
  padding: 4px;
  border-right: solid 1px var(--color-primary-super-light);

  &:nth-child(7n) {
    border-right: 0;
  }
}

.dayContainerBody {
}

.title {
  user-select: none;
}

.disabled {
  opacity: .3;
}
