.pageLayout {
  --border-table-width: 2px;
  --border-td-width: 1px;
  --border-pattern: solid;
  --border-color: black;

  --padding-width: 5px;
  --normal-font-size: 0.8rem;
  --detail-font-size: 0.65rem;

  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  .headerTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th {
      background-color: var(--color-skyblue);
      text-align: right;
      padding-right: var(--padding-width);
    }

    td {
      text-align: center;
    }

    tr:nth-child(1){
      td:nth-child(1){
        width: 15%;
      }

      td:nth-child(2){
        width: 55%;
      }

      td:nth-child(3){
        width: 15%;
      }

      td:nth-child(4){
        width: 15%;
      }
    }
  }

  .bodyTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-collapse: collapse;

    tr, th, td * {
      font-size: var(--normal-font-size);
    }

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
    }

    th {
      width: 15%;
      background-color: var(--color-skyblue);
      padding-right: var(--padding-width);
      text-align: right;
    }

    td {
      width: 35%
    }
  }
}
