.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: var(--radius-default);
  background-color: rgba(56, 132, 187, 0.5);
  color: #FFFFFF;
  font-size: 16px;
  z-index: 5;
}