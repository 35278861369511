.minimap-viewport {
    position: absolute;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    cursor: move;
}

.minimap {
    float: right;
    position: fixed;
    right: 0;
    z-index: 1;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.29);
    border: 1px solid rgba(0, 0, 0, 0.17);
    transition: transform .7s ease-in-out;
}

.minimap-container {
    width: 100%;
    height: 100%;
}

.minimap-container-scroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.minimap-children {
    background: #CCC;
    border: 1px solid black;
    box-sizing: border-box;
}
