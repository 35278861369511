.pageLayout {
  --border-table-width: 1px;
  --border-td-width: 1px;
  --border-pattern: solid;
  --border-color: #a5b2bf;
  --background-color: #cfd6de;

  --padding-width: 5px;
  --normal-font-size: 0.8rem;
  --detail-font-size: 0.65rem;

  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }

  .headerTable {
    width: 100%;
    height: 100%;
    border: var(--border-table-width) var(--border-pattern) var(--border-color);
    border-radius: 4px;
    border-collapse: collapse;

    th, td {
      border: var(--border-td-width) var(--border-pattern) var(--border-color);
      text-align: center;
    }

    th {
      background-color: var(--background-color);
      padding-right: var(--padding-width);
      font-weight: bolder;
    }

    td {

    }
  }
}